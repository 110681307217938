<template>
  <div class="pandianDetailItem">
    <div class="pandianDetailItemContent" @click="clickItem">
      <div class="index">#{{ index }}</div>
      <div class="deviceContent">
        <div class="deviceName">{{ $getDataValue(dataInfo, 'name', '') }}</div>
        <div class="deviceCategory">{{ $getDataValue(dataInfo, 'categoryName', '') }}</div>
        <div class="devicePandianTime">{{ inventoryTime }}</div>

      </div>
      <div class="deviceCode">{{ $getDataValue(dataInfo, 'code', '') }}</div>
      <div class="statusContent">
        <div class="statusMsg" :class="{'get':status == 1, 'notGet':status != 1}">{{ statusMsg }}</div>
        <div class="address">{{ deviceInventoryStatus }}</div>
        <div class="address">位置:{{ address }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@utils/utils'

export default {
  name: 'pandianDetailItem',
  props: {
    index: {
      default: 0
    },
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    deviceInventoryStatus() {
      let status = this.$getDataValue(this.dataInfo, 'deviceInventoryStatus', 0)
      if (status == '0') {
        return '在用'
      } else if (status == '1') {
        return '闲置'
      } else if (status == '2') {
        return '待报废'
      } else {
        return ''
      }
    },
    status() {
      return this.$getDataValue(this.dataInfo, 'status', 0)
    },
    statusMsg() {
      let msg = this.$getDataValue(this.dataInfo, 'status', 0)
      if (msg == 1) {
        return '已盘点'
      }
      return '未盘点'
    },
    address() {
      let msg = this.$getDataValue(this.dataInfo, 'address', '')
      if (msg == '') {
        return '-'
      }
      return msg
    },

    inventoryTime() {
      let inventoryTime = this.$getDataValue(this.dataInfo, 'inventoryTime', 0)
      if (inventoryTime == 0) {
        return '-'
      }
      let nowTime = new Date().getTime()
      let count = nowTime - inventoryTime
      if (count >= 60 * 60 * 24 * 1000.0) {
        return  this.formatDate(inventoryTime);
      }

      let msg = this.getTimeMsg(count / 1000.0)
      if (msg == '') {
        return '-'
      }
      return msg + '前'
    }
  },
  data() {
    return {}
  },
  methods: {
    clickItem: function() {
      this.$bus.emit('clickItem', this.dataInfo)
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';

.pandianDetailItem {
  padding: 5px 0px 2px 0px;


  .pandianDetailItemContent {
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .index {
      font-size: 13px;
      color: $color_primary;
      flex-shrink: 0;
    }

    .deviceContent {
      width: 130px;
      flex-shrink: 0;

      .deviceName {
        width: 100%;
        line-height: 16px;
        font-size: 16px;
        text-align: left;
        color: $color1;
        padding: 10px 0px 1px 0px;
        flex-wrap: wrap;
      }

      .deviceCategory {
        width: 100%;
        line-height: 15px;
        font-size: 14px;
        text-align: left;
        color: $color3;
        padding: 5px 0px 1px 0px;
        flex-wrap: wrap;
      }

      .devicePandianTime {
        width: 100%;
        line-height: 15px;
        font-size: 14px;
        text-align: left;
        color: $color3;
        flex-wrap: wrap;
        padding: 5px 0px 10px 0px;
      }

    }

    .deviceCode {
      width: 130px;
      flex-shrink: 0;
      flex-wrap: wrap;
      color: $color3;
      font-size: 14px;
      text-align: center;
    }

    .statusContent {
      width: calc(100% - 300px);
      flex-shrink: 0;

      .statusMsg {
        width: 100%;
        font-size: 14px;
        text-align: right;
        flex-wrap: wrap;
        padding-right: 10px;
      }

      .get {
        color: $color_primary;
      }

      .notGet {
        color: $color3;
      }

      .address {
        width: 100%;
        color: $color3;
        font-size: 14px;
        text-align: right;
        flex-wrap: wrap;
        padding-right: 10px;
      }

    }
  }


}

</style>