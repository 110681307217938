<template>
  <div class="devceiPandianDetailList">
    <div class="main-container">
      <van-pull-refresh class="main-content" v-model="isRefresh" @refresh="onRefresh">

        <van-list
            class="data-list"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >

          <div class="data-item" v-for="(item, index) in taskList" :key="index">
            <pandian-detail-item :index="(index + 1)" :data-info="item"></pandian-detail-item>

          </div>

        </van-list>

      </van-pull-refresh>

    </div>

  </div>

</template>

<script>
import PandianDetailItem from '@views/deviceManager/devicePandianList/pandianDetailItem'
export default {
  name: 'devceiPandianDetailList',
  components: { PandianDetailItem },
  props: {
    status: {
      default: ''
    },
    taskId: {
      default: ''
    }
  },
  watch:{
    taskId:{
      handler(value) {
        if (value == '') {
          return;
        }
        this.onRefresh();
      },
      immediate:true,
    },
  },
  data() {
    return {
      loading: false,
      isRefresh: false,
      finished: false,
      page: 1,
      pageSize: 30,
      taskList: []
    }
  },
  mounted() {

  },
  methods: {
    onRefresh() {
      if (this.taskId == '') {
        return;
      }
      this.isRefresh = true
      this.page = 1
      this.deviceInventoryList()
    },
    onLoad: function() {
      if (this.taskId == '') {
        return;
      }
      this.page = this.page + 1
      this.isRefresh = true
      this.deviceInventoryList()
    },
    deviceInventoryList: function() {
      let getList = () => {
        return new Promise(resolve => {
          this.$api.pandianModule.deviceInventoryList({
            page: this.page,
            pageSize: this.pageSize,
            status: this.status,
            query: '',
            taskId: this.taskId
          }).then(res => {
            if (typeof res.data == 'undefined' || res.data == null) {
              resolve({ list: [], reslut: false, totalCount: 0 })
              return
            }
            resolve({ list: res.data, reslut: true, totalCount: res.totalCount })
          }).catch(e => {
            resolve({ list: [], reslut: false, totalCount: 0 })
          })
        })
      }

      if (this.taskId == '') {
        return;
      }

      this.loading = true
      getList().then(res => {
        this.isRefresh = false
        this.loading = false
        let newList = this.page == 1 ? res.list : this.taskList.concat(res.list);
        this.taskList = newList;
        let result = newList.length >= res.totalCount;
        this.finished = result || res.list.length == 0
      })

    }
  }
}
</script>

<style lang="scss" scoped>

.devceiPandianDetailList {

}

</style>