<template>
  <div class="devicePandianList" id="devicePandianList">
    <div class="scan">
      <van-button type="info" size="normal" block @click="scanDevice">扫描资产二维码/条形码</van-button>
    </div>
    <tabs1 style="background-color: white" :color="'#0279E1'" v-model="activeTab" :options="tabList"
           @change="tabChange"
    ></tabs1>
    <div class="page-container">
      <devcei-pandian-detail-list ref="devicePandianDetailList" :status="status" :task-id="taskId"
      ></devcei-pandian-detail-list>
    </div>

    <van-dialog
        v-model="visible"
        show-cancel-button
        @close="onClose"
        @confirm="submit"
    >
      <div class="dialogContent">
        <div class="main">
          <div class="title">盘点资产:{{ name }}</div>
        </div>
        <div class="main">
          <div class="title">盘点状态:</div>
          <div class="textarea">
            <van-radio-group v-model="deviceInventoryStatus" direction="horizontal">
              <van-radio :name="0">在用</van-radio>
              <van-radio :name="1">闲置</van-radio>
              <van-radio :name="2">待报废</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="main">
          <div class="title">盘点位置:</div>
          <div class="textarea">
            <el-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 4 }"
                placeholder="请输入资产盘点位置"
                v-model="pandianAddress"
            >
            </el-input>
          </div>
        </div>
      </div>
    </van-dialog>

  </div>
</template>

<script>
import DevceiPandianDetailList from '@views/deviceManager/devicePandianList/devceiPandianDetailList'
import { Loading } from 'element-ui'

export default {
  name: 'devicePandianList',
  components: { DevceiPandianDetailList },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading()
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      loadingView:null,
      loading:false,
      selectItem: null,
      visible: false,
      pandianAddress: '',
      deviceInventoryStatus: 0,
      status: '',
      taskId: '',
      serialNumber: '',
      activeTab: '',
      tabList: [
        {
          title: '全部',
          path: '',
          status: ''
        },
        {
          title: '未盘点',
          path: '3',
          status: '3'
        },
        {
          title: '已盘点',
          path: '1',
          status: '1'
        }
      ]
    }
  },
  computed: {
    name() {
      if (this.selectItem == null) {
        return ''
      }
      return this.$getDataValue(this.selectItem, 'name', '')
    }
  },
  beforeDestroy() {
    this.$bus.off('clickItem', this.clickItem)
  },
  mounted() {
    // 配置微信jssdk
    if (typeof localStorage['corpInfo'] != 'undefined') {
      try {
        let corpInfo = JSON.parse(localStorage['corpInfo'])
        this.wxConfig(corpInfo)
      } catch (e) {
      }
    }
    let query = this.$route.query
    if (this.$valueIsExist(query, 'taskId')) {
      this.taskId = query['taskId']
    }
    if (this.$valueIsExist(query, 'serialNumber')) {
      this.serialNumber = query['serialNumber']
    }

    this.$bus.on('clickItem', this.clickItem)
  },
  methods: {
    startLoading: function() {
      let dom = document.getElementById('devicePandianList')
      if (typeof dom == 'undefined' || dom == null) {
        return
      }
      this.loadingView = Loading.service({ target: dom })
    },
    scanDevice: function() {
      let findDevice = (code) => {
        return new Promise(resolve => {
          let getPost = {
            deleted: 0,
            deviceType: '',
            query: code,
            page: 1,
            pageSize: 30,
            order: '',
            orderType: '',
            tenantId: '',
            isTenantIdChild: 1,
            categoryId: ''
          }
          this.$api.assetModule.getDeviceList(getPost).then(res => {
            if (typeof res.data == 'undefined' || res.data == null || res.data.length == 0) {
              resolve({ reslut: false, data:{} })
              return
            }
            resolve({ data: res.data[0], reslut: true })
          }).catch(e => {
            resolve({ reslut: false, data:{} })
          })
        })
      }

      this.loading = true;
      this.scanQRCode().then((res) => {
        if (res.result == false) {
          this.$toast.fail('请扫描二维码或者条形码')
          this.loading = false;
          return
        }
        findDevice(res.res).then(res1 => {
          this.loading = false;
          if (res1.result == false) {
            this.$toast.fail('没有该设备');
            return
          }
          this.selectItem = res1.data;
          this.visible = true;
        })
      })
    },
    clickItem: function(item) {
      this.selectItem = item
      this.visible = true
    },
    onClose: function() {
      this.visible = false
    },
    reset:function() {
      this.selectItem = null
      this.deviceInventoryStatus = 0
      this.pandianAddress = ''
    },
    submit: function() {
      let updateStatus = () => {
        return new Promise(resolve => {
          if (this.selectItem == null) {
            resolve({ result: false })
            return
          }
          let userInfo = this.$store.getters.userInfo
          let post = {}
          post['userId'] = userInfo.id
          post['customerId'] = ''
          post['tenantId'] = userInfo.tenantId
          post['json'] = JSON.stringify([{
            'address': this.pandianAddress,
            'timestamp': new Date().getTime(),
            'deviceId': this.$getDataValue(this.selectItem, 'id', ''),
            'serialNumber': this.serialNumber,
            'taskId': this.taskId,
            'deviceInventoryStatus': this.deviceInventoryStatus
          }])

          this.$api.pandianModule.deviceInventoryRecordBatchAdd(post).then(res => {
            resolve({ reslut: true })
          }).catch(e => {
            resolve({ reslut: false })
          })
        })
      }

      this.$dialog
          .confirm({
            message: '是否提交该资产盘点'
          }).then(res => {
        updateStatus().then(res => {
          if (res.reslut) {
            this.$toast.success('提交成功')
            this.reset()
            this.$nextTick(() => {
              this.$refs['devicePandianDetailList'].onRefresh()
            })
          } else {
            this.$toast.fail('提交失败')
          }
        })
      }).catch(e => {
        this.reset()
      })

    },
    tabChange(e) {
      if (e == this.activeTab) {
        return
      }
      this.activeTab = e
      let item = null
      for (let i = 0; i < this.tabList.length; i++) {
        let data = this.tabList[i]
        if (data.path == e) {
          item = data
          break
        }
      }
      if (item == null) {
        return
      }
      this.status = item['status']
      this.$nextTick(() => {
        this.$refs['devicePandianDetailList'].onRefresh()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';

.devicePandianList {
  height: 100%;

  .page-container {
    height: calc(100% - 32px - 70px);
    overflow-y: auto;
  }

  .scan {
    padding: 10px;
  }

  .dialogContent {
    padding: 12px 22px;

    .main {
      .title {
        padding: 12px 0;
      }

      ::v-deep .el-textarea__inner {
        border: 0 none;
        background: #fbfbfb;
      }
    }
  }

}

</style>